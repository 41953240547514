import {required, ValidationResult, Validator} from '@/types/validation/ValidationResult';
import {AuthenticationRequest} from '@/types/authentication/Authentication';

export class SignInChecker {
  result: ValidationResult;

  constructor(result: AuthenticationRequest) {
    const checker = new ValidationResult(SignInValidator);
    checker.validate(result);
    this.result = checker;
  }

  get hasErrors(): boolean {
    return this.result.hasErrors;
  }

  get getMessages(): string[] {
    return this.result.getMessages();
  }
}

export const SignInValidator: Validator[] = [
  {
    name: 'assessorNumber',
    constraints: [
      {method: required, message: '査定士番号を入力してください'},
      {method: assessorNumberFormat, message: '査定士番号は8桁を入力ください'},
    ],
  },
  {
    name: 'officeCode',
    constraints: [
      {method: required, message: '支所を選択してください'},
    ],
  },
  {
    name: 'password',
    constraints: [
      {method: required, message: 'パスワードを入力してください'},
    ],
  },
];

export function assessorNumberFormat(value: string): boolean {
  if (!value) {
    return true;
  }
  if (value.match(/JAAI-/)) {
    return value.length === 7;
  }
  return value.length === 8;
}
