export interface Information {
  id: number | null;
  text: string;
  officeCode: string;
  officeName: string;
}

export const EmptyInformation: Information = {
  id: null,
  text: '',
  officeCode: '',
  officeName: '',
};
