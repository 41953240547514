
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {SignInChecker} from '@/validator/sign/SignInChecker';
  import {AuthenticationRequest} from '@/types/authentication/Authentication';
  import {tokenModule} from '@/stores/authentication/Authentication';
  import {loginModule} from '@/stores/authentication/Account';
  import SignGuideLine from '@/views/sign/SignGuideLine.vue';
  import AlertModal from '@/components/molecules/modal/AlertModal.vue';
  import {EmptyInformation, Information} from '@/types/information/Information';
  import api from '@/infrastructure/api/API';
  import SignInAlertModal from '@/views/sign/SignInAlertModal.vue';

  @Component({
    components: {
      SignInAlertModal,
      SignGuideLine,
    },
  })
  export default class SignIn extends Vue {
    // data
    request: AuthenticationRequest = {
      assessorNumber: '',
      password: '',
    };
    informations: Information[] = [];
    messages: string[] = [];

    // props
    @Prop({default: ''})
    assessorNumber!: string;

    // computed
    get hasError(): boolean {
      return this.messages.length > 0;
    }

    get hasInformation(): boolean {
      return this.informations.length > 0;
    }

    get hasContact(): boolean {
      return loginModule.hasUnreadAnswer || loginModule.hasUserInquiry;
    }

    // methods
    async signIn(): Promise<void> {
      this.messages = [];

      const validator = new SignInChecker(this.request);
      if (validator.hasErrors) {
        this.messages = validator.getMessages;
        return;
      }

      await tokenModule.authenticate(this.request)
        .then(async (response: any) => {
          await this.getInformation();
          await this.getAccount();
          this.openAlertModal();
        })
        .catch((error: any) => {
          // 入力エラー
          if (error.data.errors) {
            this.messages = error.data.errors;
            return;
          }
          if (error.data.message) {
            this.messages = [error.data.message];
            return;
          }
        });

      if (!this.hasError && !this.hasInformation && !this.hasContact) {
        await this.toMypage();
      }
    }

    async getAccount(): Promise<void> {
      await loginModule.getAccount();
    }

    async getInformationHead(): Promise<void> {
      await api.getInformationHead()
        .then((response: Information) => {
          this.informations.push(response);
        });
    }
    async getInformation(): Promise<void> {
      await api.getInformation()
          .then((response: Information[]) => {
            this.informations = response;
          });
    }

    openAlertModal(): void {
      if (this.hasInformation || this.hasContact) {
        this.$modal.show('InformationAlertModal');
      }
    }

    async toMypage(): Promise<void> {
      await this.getAccount();
      this.$router.push({name: 'mypage'});
    }

    validate(): void {
      this.request.assessorNumber = this.request.assessorNumber.replace(/[^A-Z\-0-9]/g, '');
    }

    // life cyclehook
    created(): void {
      this.request.assessorNumber = this.assessorNumber;
      this.getInformationHead();
    }
  }
