
  import {Component, Vue} from 'vue-property-decorator';
  import api from '@/infrastructure/api/API';
  import {EmptyInformation, Information} from '@/types/information/Information';

  @Component({})
  export default class SignGuideLine extends Vue {
    // data
    headInformation: Information = EmptyInformation;

    // computed
    get hasHeadInformation(): boolean {
      return !!this.headInformation.id;
    }

    // method
    async getInformationHead(): Promise<void> {
      await api.getInformationHead()
        .then((response: Information) => {
          this.headInformation = response;
        });
    }

    // life cyclehook
    created(): void {
      this.getInformationHead();
    }
  }
