
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

  @Component({})
  export default class SignInAlertModal extends Vue {
    @Prop({})
    name!: string;
    @Prop({})
    title!: string;
    @Prop({default: '340px'})
    width!: string;

    @Emit('done')
    done(): void {
      // emit
    }

    // method
    touch(event: Event): void {
      this.done();
      event.preventDefault();
    }
  }
